var render = function render(){var _vm=this,_c=_vm._self._c;return (!this.checkNpl())?_c('AppHeaderDropdown',{attrs:{"right":""}},[_c('template',{slot:"header"},[_c('i',{staticClass:"fa fa-plus-square"})]),_c('template',{slot:"dropdown"},[(this.checkPermission('admin.loan-repayments.repair'))?_c('b-dropdown-item',{attrs:{"to":{ name: 'admin.loan-repayments.repair' }}},[_vm._v("Засварын гүйлгээ")]):_vm._e(),(this.checkPermission('admin.loan-repayments.repair'))?_c('b-dropdown-divider'):_vm._e(),(this.checkPermission('admin.loan-repayments.new'))?_c('b-dropdown-item',{attrs:{"to":{ name: 'admin.loan-repayments.new' }}},[_vm._v("Төлбөр бүртгэх")]):_vm._e(),(this.checkPermission('admin.loan.new'))?_c('b-dropdown-item',{attrs:{"to":{ name: 'admin.customer-loan-accounts.new' }}},[_vm._v("Зээл бүртгэх")]):_vm._e(),(
        this.checkPermission('admin.loan-repayments.international') ||
          ['davaakhuu@numur.mn', 'bulgan@numur.mn'].includes(_vm.$store.getters.email)
      )?_c('b-dropdown-divider'):_vm._e(),(
        this.checkPermission('admin.loan-repayments.international') ||
          ['davaakhuu@numur.mn', 'urgelj@seznam.cz', 'bulgan@numur.mn'].includes(_vm.$store.getters.email)
      )?_c('b-dropdown-item',{attrs:{"to":{ name: 'admin.customer.create' }}},[_vm._v("Хэрэглэгч бүртгэх")]):_vm._e()],1)],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }