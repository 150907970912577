<template>
  <AppHeaderDropdown right v-if="!this.checkNpl()">
    <template slot="header">
      <i class="fa fa-plus-square"></i>
    </template>
    <template slot="dropdown">
      <b-dropdown-item
        v-if="this.checkPermission('admin.loan-repayments.repair')"
        :to="{ name: 'admin.loan-repayments.repair' }"
        >Засварын гүйлгээ</b-dropdown-item
      >
      <b-dropdown-divider
        v-if="this.checkPermission('admin.loan-repayments.repair')"
      />
      <b-dropdown-item
        v-if="this.checkPermission('admin.loan-repayments.new')"
        :to="{ name: 'admin.loan-repayments.new' }"
        >Төлбөр бүртгэх</b-dropdown-item
      >
      <b-dropdown-item
        v-if="this.checkPermission('admin.loan.new')"
        :to="{ name: 'admin.customer-loan-accounts.new' }"
        >Зээл бүртгэх</b-dropdown-item
      >
      <b-dropdown-divider
        v-if="
          this.checkPermission('admin.loan-repayments.international') ||
            ['davaakhuu@numur.mn', 'bulgan@numur.mn'].includes($store.getters.email)
        "
      />
      <b-dropdown-item
        v-if="
          this.checkPermission('admin.loan-repayments.international') ||
            ['davaakhuu@numur.mn', 'urgelj@seznam.cz', 'bulgan@numur.mn'].includes($store.getters.email)
        "
        :to="{ name: 'admin.customer.create' }"
        >Хэрэглэгч бүртгэх</b-dropdown-item
      >
    </template>
  </AppHeaderDropdown>
</template>

<script>
import { HeaderDropdown as AppHeaderDropdown } from "@coreui/vue";
export default {
  name: "DefaultHeaderDropdownAccnt",
  components: {
    AppHeaderDropdown,
  },
  data: () => {
    return {};
  },
  methods: {},
};
</script>
